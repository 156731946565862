import checklistsList from './_checklistsList.js'
import listChip from './_listChip.js'
import textFields from './_textFields.js'
import memberAvatars from '@/templates/cards/_memberAvatars.js'

export default class {
  static specs(card) {
    return [
      textFields.titleSpec(card),
      {
        "view": "spacer",
        "height": 6
      },
      this.accessorySpec(card),
      {
        "view": "spacer",
        "height": 12
      },
      textFields.descriptionSpec(card),
      checklistsList.spec(card)
    ]
  }

  static accessorySpec(card) {
    return {
      "view": "panels/split",
      "width": "matchParent",
      "left": {
        "childViews": [
          listChip.spec(card)
        ]
      },
      // Hide it for now. Not sure if this feature is well-integrated with other features.
      // "center": {
      //   "width": "matchParent",
      //   "padding": { left: 8 },
      //   "childViews": card.shapeUp.scopeCardId ? [] : [
      //     this.riskLevelSpec(card)
      //   ]
      // },
      "right": {
        "childViews": [
          memberAvatars.spec(card)
        ]
      }
    }
  }

  static riskLevelSpec(card) {
    const options = [
      'Low risk',
      'Slightly uncertain',
      'Highly uncertain',
    ]

    const icons = $tpu.keys.icnsRiskLevel()
    const selectedIndex = card.shapeUp.riskLevel
    const selectedIcon = icons[selectedIndex]

    return {
      "view": "panels/horizontal",
      "childViews": [
        // {
        //   "view": "icon",
        //   "icon": {
        //     "material": {
        //       name: "trending_up"
        //     }
        //   },
        // },
        {
          "view": "chip",
          "styleClasses": ["small"],
          "icon": selectedIcon ? {
            "material": {
              name: selectedIcon
            }
          } : null,
          "text": options[selectedIndex],
          "onClick": {
            "action": "sheets/select",
            // "message": "Select a list to move this card",
            "buttons": options.map((option, index) => {
              const icon = icons[index] || 'check_circle_outline'
              return {
                "text": option,
                "icon": {
                  "material": {
                    name: icon
                  }
                },
                "onClick": {
                  "action": "commands/custom",
                  "name": "cards/setData",
                  "properties": {
                    "cardId": card.id,
                    "formData": {
                      [$tpu.keys.crdRiskLevel()]: index
                    }
                  }
                },
                "type": "button"
              }
            })
          }
        }
      ]
    }
  }
}
